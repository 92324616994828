<template>
    <div class="report-element row">
        <b-col cols="4" style="margin: auto">
            <span class="filename">{{ element.fileName }}</span>
        </b-col>
      <template v-if="currentRole === 'manager'">
        <b-col cols="2" style="margin: auto">
          <span class="date">{{ $d(Date.parse(element.createdAt), 'longDate') }}</span>
        </b-col>
        <b-col cols="2" style="margin: auto">
          <span class="date">{{ element.academy }}</span>
        </b-col>
        <b-col cols="2" style="margin: auto">
          <span class="date">{{ element.user }}</span>
        </b-col>
        <b-col cols="2" class="d-flex justify-content-end">
          <pcg-btn @click="deleteReport" class="btn-danger" v-if="hasRight('export_pbn') || currentRole === 'manager'">
            {{ $t('components.polon_element.delete') }}
          </pcg-btn>
          <pcg-btn @click="downloadReport" class="pcg-button pcg-main-btn pcg-normal-btn" :disabled="xmlSpinner"  v-if="hasRight('export_pbn')">
            {{ $t('general.download')}}
          </pcg-btn>
        </b-col>

      </template>
      <template v-else>
        <b-col cols="3" style="margin: auto">
          <span class="date">{{ $d(Date.parse(element.createdAt), 'longDate') }}</span>
        </b-col>
        <b-col cols="4" class="d-flex justify-content-end">
          <pcg-btn @click="deleteReport" class="btn-danger" v-if="hasRight('export_pbn') || currentRole === 'manager'">
            {{ $t('components.polon_element.delete') }}
          </pcg-btn>
          <pcg-btn @click="downloadReport" class="pcg-button pcg-main-btn pcg-normal-btn" :disabled="xmlSpinner"  v-if="hasRight('export_pbn')">
            {{ $t('general.download')}}
          </pcg-btn>
        </b-col>
      </template>
    </div>
</template>

<script>
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import hasRight from '../mixins/has_right'
import ApiReports from '../api/reports'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportElement',
  // eslint-disable-next-line camelcase
  mixins: [hasRight, unexpectedError, waitForJobs],
  data: function () {
    return {
      jid: '',
      currentUser: {
        id: null,
        role: null
      }
    }
  },
  props: {
    element: Object,
    xmlSpinner: Boolean
  },
  computed: {
    ...mapGetters('auth', ['currentRole'])
  },
  beforeMount () {
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      role: this.$store.getters['auth/currentRole']
    }
  },
  watch: {
    // jid (newValue) {
    //   if (newValue !== null && newValue !== '') {
    //     this.xmlSpinner = true
    //     this.waitForJob(this.jid, this.xmlParsed, this.unexpectedError)
    //   }
    // }
  },
  created () {
  },
  methods: {
    deleteReport () {
      this.$emit('deleteReport', this.element.id)
      // ApiReports.deleteFile(this.element.id)
      //   .then(result => {
      //     this.$emit('deleted')
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
    downloadReport () {
      this.$emit('downloadReport', this.element.id, this.element.fileName)
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';

    .btn-danger {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;

        &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
        }
    }

    .filename {
        color: $pcg-gray;
        font-weight: 400;
    }

    .date {
        color: $pcg-gray;
        font-weight: 400;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .btn-danger {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
            &:hover {
                color: #fff;
                background-color: #c82333;
                border-color: #bd2130;
            }
        }
        .filename {
            color: $pcg-gray;
        }
        .date {
            color: $pcg-gray;
        }
    }
</style>
