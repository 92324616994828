import api from './index'

const resource = 'polon_xml'

export default {
  getXmlFiles (page = null) {
    let url = ''
    if (page) {
      url = resource + '?page=' + page
    } else {
      url = resource
    }
    return api.get(url)
  },
  updateAddEmployee (id, addEmployee) {
    let url = resource
    url += '/' + id + '/add_employee'
    const body = {
      add_employee: addEmployee
    }
    return api.put(url, body)
  },
  putXmlFile (form) {
    return api.post(resource, form)
  },
  getXmlFile (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },
  deleteXml (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  parseXmlFile (id) {
    let url = resource
    url += '/' + id + '/parse'
    return api.get(url)
  },
  importPbn (params) {
    const url = `${resource}/import?${params}`
    return api.post(url)
  },
  importPolon (params) {
    const url = `${resource}/import_polon`
    return api.post(url, params)
  },
  importPolonEmployees (params) {
    const url = `${resource}/import_polon_employees`
    return api.post(url, params)
  },
  importPbnDictionaries (params) {
    const url = `${resource}/import_pbn_dictionaries`
    return api.post(url, params)
  }
}
