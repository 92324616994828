<template>
  <base-beam>
    <dn-beam mobile-active-class="justify-content-start" mobile-class="justify-content-between">
      <template>
        <span class="text-lowercase selected-elements ml-2 mr-4">
          {{ count }} {{ $t('views.achievement.list.selected_elements') }}
        </span>

        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <pcg-btn v-if="hasRight('export_employees')" size="small" variant="additional" @click="exportXlsxModal">{{ $t('views.scientists.list.employee_export') }}</pcg-btn>
        <pcg-btn v-if="hasRight('export_employees')" size="small" variant="additional" @click="exportPdfModal">{{ $t('views.scientists.list.employee_export_pdf') }}</pcg-btn>
        <pcg-btn v-if="hasRight('employees_calculate_slots')" size="small" variant="additional" @click="calculateSlotsModal">{{ $t('views.scientists.list.calculate_slots') }}</pcg-btn>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
      </template>

      <template #mobile_beam>
        <div class="ml-4">
          {{ count }}
          <span class="text-lowercase mr-4">
            {{ $t('views.achievement.list.selected_elements') }}
          </span>
        </div>
      </template>

      <template #options>
        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <pcg-btn v-if="hasRight('export_employees')" size="small" variant="additional" @click="exportXlsxModal">{{ $t('views.scientists.list.employee_export') }}</pcg-btn>
        <pcg-btn v-if="hasRight('export_employees')" size="small" variant="additional" @click="exportPdfModal">{{ $t('views.scientists.list.employee_export_pdf') }}</pcg-btn>
        <pcg-btn v-if="hasRight('employees_calculate_slots')" size="small" variant="additional" @click="calculateSlotsModal">{{ $t('views.scientists.list.calculate_slots') }}</pcg-btn>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
      </template>
      <b-modal ref="exportPdfConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportPdf"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.scientists.list.employee_export_pdf') }}</div>
        <div class="confirm-text">{{ $tc('views.scientists.list.employee_export_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="calculateSlotsConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="calculateSlots"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.scientists.list.calculate_slots') }}</div>
        <div class="confirm-text">{{ $tc('views.scientists.list.calculate_slots_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="exportXlsxConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportXlsx"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.scientists.list.employee_export') }}</div>
        <div class="confirm-text">{{ $tc('views.scientists.list.employee_export_confirmation', count) }}</div>
      </b-modal>
    </dn-beam>
  </base-beam>
</template>

<script>
import DnBeam from './DnBeam'
import BaseBeam from './BaseBeam'
import Spinner from '../Spinner'
import hasRight from '../../mixins/has_right'

export default {
  name: 'DnBeamScientistsList',
  mixins: [hasRight],
  components: {
    BaseBeam,
    DnBeam,
    Spinner
  },
  data: function () {
    return {
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      },
      status: null,
      currentUser: null
    }
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    spinnerAll: Boolean
  },
  beforeMount () {
    this.currentUser = this.$store.getters['auth/id']
  },
  methods: {
    exportXlsx () {
      this.$emit('exportEmployees')
    },
    exportPdf () {
      this.$emit('exportPdfEmployees')
    },
    exportPdfModal () {
      this.$refs.exportPdfConfirm.show()
    },
    calculateSlots () {
      this.$emit('employeesCountSlots')
    },
    calculateSlotsModal () {
      this.$refs.calculateSlotsConfirm.show()
    },
    exportXlsxModal () {
      this.$refs.exportXlsxConfirm.show()
    },
    cancelMultiCheckbox () {
      this.$emit('cancelMultiCheckbox')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .my-spinner {
    color: $main-active-color
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .cancel-checkbox {
    color: $main-active-color;
    font-weight: lighter;
    font-size: $font-size-s;
    margin-right: .5rem;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }

  .selected-elements {
    color: $pcg-black;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-spinner {
      color: $main-active-color
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .cancel-checkbox {
      color: $main-active-color;
      &:hover {
        color: $hover-color;
      }
    }
    .selected-elements {
      color: $pcg-black;
    }
  }
</style>
