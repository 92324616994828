<template>
  <div class="component-container">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else-if="$store.getters['auth/signedIn']">
      <div class="oneline d-flex justify-content-center">
        <div class="pcg-slide-tabs">
          <button class="pcg-slide-tab-btn button-outline" @click="getAllDisciplines" :class="{ 'active': showAll }">{{ $t('views.disciplines.show_all') }}</button>
          <button class="pcg-slide-tab-btn button-outline act" @click="getWithAchievements" :class="{ 'active': withAchievements }">{{ $t('views.disciplines.show_with_achievements') }}</button>
          <button class="pcg-slide-tab-btn button-outline" @click="getEvaluatedDisciplines" :class="{ 'active': onlyEvaluated }">{{ $t('views.disciplines.show_evaluated') }}</button>
        </div>
      </div>
      <div v-if="hasRight('multiple_discipline_evaluation')" class="d-flex justify-content-end">
        <pcg-btn size="small" @click="multipleEvaluationModal" :disabled="evaluationSpinner">
          <span>{{ $t('views.disciplines.multiple_evaluation') }}</span>
          <spinner v-if="evaluationSpinner" class="my-spinner"></spinner>
          <span v-if="cableStatusLive" class="live-cable">({{ cableStatusLive }})</span>
        </pcg-btn>
      </div>
      <b-modal ref="evaluationModal"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="multipleEvaluate"
               @hide="evaluationEsc"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.disciplines.multiple_evaluation') }}</div>
        <pcg-select
            class="my-select"
            :options="evaluationOptions"
            :label="$t('views.disciplines.evaluations')"
            :default-option="{ text: $t('general.select'), value: '' }"
            v-model="chosenEvaluation"
        />
      </b-modal>
      <div class="one-main-field" v-for="mainField in mainFields" :key="mainField.id">
        <h2 v-if="$i18n.locale === 'pl'" class="header dn-header">
          {{ mainField.name }}
        </h2>
        <h2 v-else class="header dn-header">
          {{ mainField.nameEn }}
        </h2>
        <pcg-box>
          <div>
            <div class="one-row" v-for="discipline in mainField.disciplines" :key="discipline.id">
              <div class="discipline-header">
                <h3 class="discipline-name" v-if="$i18n.locale === 'pl'">
                  <router-link :to="{ name: 'discipline_show_path', params: { id: discipline.id } }" >
                    {{ discipline.name }}
                  </router-link>
                </h3>
                <h3 v-else class="discipline-name">
                  <router-link :to="{ name: 'discipline_show_path', params: { id: discipline.id } }" >
                    {{ discipline.nameEn }}
                  </router-link>
                </h3>
                <div class="discipline-evaluated">
                  <pcg-checkbox
                    class="my-checkbox"
                    variant="normal"
                    :disabled="!(hasRight('edit_discipline') || hasRight('edit_own_discipline')) || recalcSpinner"
                    v-model="discipline.evaluated"
                    @input="changeEvaluated(discipline)"
                  >{{ $t('components.dn_main_field.evaluated') }}</pcg-checkbox>
                </div>
                <div class="discipline-evaluated">
                  <spinner v-if="discipline.recalcSlots" class="my-spinner"></spinner>
                </div>
              </div>
              <b-row class="my-space">
                <b-col class="col-12 col-sm-2 col-lg-2 d-flex align-items-center" :class="{ 'col-md-12': menuOpen }">
                  <div>
                    <span class="points-number">{{ disciplinePoints(discipline) }}</span>
                    <span class="points-text">{{ $tc('components.dn_field.points', disciplinePoints(discipline)) }}</span>
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-5 col-lg-3 second-column" :class="{ 'col-md-12': menuOpen, 'col-md-4': !menuOpen }">
                  <div>
                    <span v-if="discipline.short === 'DS010801N' || discipline.short === 'DS010802N' || discipline.short === 'DS010803N'" class="filling-slots">
                      {{ $t('components.dn_field.filling_limits') }}
                    </span>
                    <span v-else class="filling-slots">
                      {{ $t('components.dn_field.filling_slots') }}
                    </span>
                    <span class="filling-slots-percent">{{ fillingSlots(discipline) }}%</span>
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-5 col-lg-7" :class="{ 'col-md-12': menuOpen, 'col-md-5': !menuOpen }">
                  <dn-progress-bar :progress-percent="fillingSlots(discipline)"></dn-progress-bar>
                </b-col>
              </b-row>
              <b-row class="my-icon-space">
                <b-col class="col-12 col-sm-2 col-lg-2 d-flex align-items-center" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                </b-col>
                <b-col class="col-12 col-sm-3 col-lg-3 second-column" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                  <div>
                    <span class="my-text">{{ $t('components.dn_field.points_n') }}:</span>
                    <span class="my-number">{{ numberNPoints(discipline) }}</span>
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-4 col-lg-4" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                  <div>
                    <span class="my-text">{{ $t('components.dn_field.applications', {from: years[0], to: years[years.length - 1]}) }}:</span>
                    <span class="my-number">{{ discipline.numberN }}</span>
                    <router-link :to="{ name: 'scientists_list_path', query: { datesFrom: years[0], datesTo: years[years.length - 1], byDiscipline: discipline.id, numberNStatement: true, endOfYear: true } }">
                      <span class="my-number">({{ $tc('components.dn_field.people', discipline.numberNStatements) }})</span>
                    </router-link>
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-3 col-lg-3" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                  <div>
                    <img src="../../assets/images/article.svg" class="my-icon" alt="">
                    <span class="my-text">{{ $t('components.dn_field.number_of_publications') }}</span>
                    <span class="filling-slots-percent">{{ discipline.disciplinePublications }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="my-icon-space">
                <b-col class="col-12 col-sm-2 col-lg-2 d-flex align-items-center" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                </b-col>
                <b-col class="cols-12 col-sm-10 col-lg-10 second-column" :class="{ 'col-md-12': menuOpen, 'col-md-9': !menuOpen }">
                  <dn-discipline-element :discipline="discipline" :admin="admin" v-if="hasRight('view_discipline_manager')"></dn-discipline-element>
                </b-col>
              </b-row>
              <b-row class="my-icon-space">
                <b-col class="col-12 col-sm-2 col-lg-2 d-flex align-items-center" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                </b-col>
                <b-col class="cols-12 col-sm-10 col-lg-10 second-column" :class="{ 'col-md-12': menuOpen, 'col-md-9': !menuOpen }">
                  <dn-discipline-user :discipline="discipline" :admin="admin"></dn-discipline-user>
                </b-col>
              </b-row>
              <!--            <b-row class="my-icon-space" v-if="discipline.numberN > 12">-->
              <!--              <b-col class="col-12 col-sm-2 d-flex align-items-center">-->
              <!--              </b-col>-->
              <!--              <b-col class="col-12 col-sm-6">-->
              <!--                <div>-->
              <!--                  <span>-->
              <!--                    <img src="../../assets/images/i-black.svg" class="icon-too-many"/>-->
              <!--                  </span><span class="my-icon-text">{{ $t('components.dn_field.too_many_job_positions') }}</span>-->
              <!--                </div>-->
              <!--              </b-col>-->
              <!--            </b-row>-->
              <b-row class="my-icon-space">
                <b-col class="col-12 col-sm-2 col-lg-2 d-flex align-items-center" :class="{ 'col-md-12': menuOpen, 'col-md-3': !menuOpen }">
                </b-col>
                <b-col class="col-12 col-sm-5 col-lg-3 second-column" :class="{ 'col-md-12': menuOpen, 'col-md-4': !menuOpen }">
                  <span class="my-text">{{ $t('components.dn_field.number_n_last_year', {year: years[years.length - 1]}) }}</span>
                  <span class="my-number">{{ discipline.numberNLastYear }}</span>
                </b-col>
                <b-col v-if="discipline.numberNLastYear < 12" class="col-12 col-sm-5 col-lg-4" :class="{ 'col-md-12': menuOpen, 'col-md-4': !menuOpen }">
                  <div>
                    <span tabindex="0" v-b-tooltip.hover v-b-tooltip="$t('components.dn_field.not_enough_job_positions_tooltip')">
                      <img src="../../assets/images/exclamation-mark-red.svg" class="icon-not-enough" :alt="$t('general.exclamation_mark')"/>
                    </span>
                    <span class="my-icon-text">{{ $t('components.dn_field.not_enough_job_positions') }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </pcg-box>
      </div>
      <dn-loader v-if="disciplineEndSpinner" class="my-loader"></dn-loader>
    </div>
  </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import ApiDiscipline from '../../api/disciplins'
import DnProgressBar from '../../components/DnProgressBar'
import DnDisciplineElement from '../../components/DnDisciplineElement'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import hasRight from '../../mixins/has_right'
import waitForJobs from '../../mixins/wait_for_jobs'
import Spinner from '../../components/Spinner'
import DnDisciplineUser from '../../components/DnDisciplineUser'
import { mapGetters } from 'vuex'
import ApiEvaluations from '../../api/evaluations'
export default {
  name: 'disciplines',
  mixins: [hasRight, setTitle, loaderDelay, waitForJobs],
  components: {
    DnDisciplineElement,
    DnProgressBar,
    DnLoader,
    Spinner,
    DnDisciplineUser
  },
  channels: {
    ChatChannel: {
      connected () {
        console.log('connected')
      },
      rejected () {
        console.log('rejected')
      },
      received (data) {
        console.log(data)
        this.cableStatus = data.body
      },
      disconnected () {
        console.log('dc')
      }
    }
  },
  data: function () {
    return {
      headerTitle: 'views.disciplines.title',
      headerSubtitle: '',

      roles: [],
      admin: false,
      mainFields: [],
      onlyEvaluated: false,
      showAll: false,
      withAchievements: true,
      recalcSpinner: false,
      recalcDiscipline: null,
      evaluations: null,
      evaluationOptions: [],
      chosenEvaluation: null,
      evaluationSpinner: false,
      cableStatus: null,
      currentUser: null,
      disciplineEndSpinner: false,
      page: 1,
      hasMore: true
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.roles = this.$store.getters['auth/roles']
    if (this.roles === null) {
      this.$router.push({ name: 'login_path' })
    } else {
      const startTime = performance.now()
      ApiEvaluations.getEvaluations()
          .then(result => {
            this.evaluations = result.data.datas
            this.evaluations.forEach(ev => {
              this.evaluationOptions.push({ text: ev.name, value: ev.id })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDiscipline.getDisciplinsList(this.page)
        .then(result => {
          if (result && result.data && result.data.fields && result.data.fields.length > 0) {
            this.page += 1
            this.mainFields = result.data.fields
            if (this.mainFields) {
              for (let i = 0; i < this.mainFields.length; i++) {
                for (let j = 0; j < this.mainFields[i].disciplines.length; j++) {
                  if (this.mainFields[i].disciplines[j].evaluated === null) {
                    this.mainFields[i].disciplines[j].evaluated = false
                  }
                }
              }
            }
            this.loadDisciplines()
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
            this.hasMore = false
          }
        })
        .finally(() => {
          this.loadingDone = true
          const endTime = performance.now()
          const timeTaken = (endTime - startTime) / 1000
          console.log(`Pobranie dyscyplin trwało ${timeTaken} sekund.`)
        })
    }
  },
  beforeMount () {
    this.currentUser = this.$store.getters['auth/id']
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.disciplines.title')
    })
    this.checkRoles()
  },
  beforeDestroy () {
    this.$cable.unsubscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('period', ['years']),
    cableStatusLive () {
      return this.cableStatus
    }
  },
  methods: {
    checkRoles () {
      if (this.roles !== null) {
        for (let i = 0; i < this.roles.length; i++) {
          if (this.hasRight('edit_discipline')) {
            this.admin = true
          } else {
            this.admin = false
          }
        }
      }
    },
    fillingSlots (discipline) {
      var fillingSlots = Math.round(discipline.fillingSlots * 100) / 100.0
      if (fillingSlots > 100) {
        fillingSlots = 100
      }
      return fillingSlots
    },
    allJobs (fieldIdx) {
      let jobs = 0
      this.mainFields[fieldIdx].disciplines.forEach(d => {
        jobs += d.countedJobs
      })
      return jobs
    },
    changeEvaluated (discipline) {
      ApiDiscipline.changeEvaluated(discipline)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.recalcDiscipline = discipline
            this.recalcSpinner = true
            this.recalcDiscipline.recalcSlots = true
            this.waitForJob(jobId, this.disciplineEvaluated, this.disciplineError)
          }
        })
        .catch(error => {
          console.log(error.response.data)
          this.editEmployment()
        })
    },
    disciplineEvaluated () {
      this.recalcDiscipline.recalcSlots = false
      this.recalcSpinner = false
    },
    disciplineError () {
      this.recalcDiscipline.recalcSlots = false
      this.recalcSpinner = false
    },
    showScienceField (index) {
      if (this.mainFields) {
        for (let i = 0; i < this.mainFields[index].disciplines.length; i++) {
          if (this.mainFields[index].disciplines[i].evaluated === true) {
            return true
          }
        }
      }
      return false
    },
    numberNPoints (discipline) {
      if (discipline.numberN > 0) {
        return (discipline.points / discipline.numberN).toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    disciplinePoints (discipline) {
      if (discipline.points > 0) {
        return discipline.points.toFixed(2) * (100 / 100)
      } else {
        return 0
      }
    },
    loadDisciplines () {
      if (!this.hasMore) return
      this.disciplineEndSpinner = true
      if (this.showAll) {
        ApiDiscipline.getDisciplinsList(this.page, this.showAll)
            .then(result => {
              if (result && result.data && result.data.fields && result.data.fields.length > 0) {
                this.page += 1
                result.data.fields.forEach(field => {
                  const idx = this.mainFields.findIndex(mf => mf.id === field.id)
                  if (idx >= 0) {
                    field.disciplines.forEach(discipline => {
                      const idx2 = this.mainFields[idx].disciplines.findIndex(d => d.id === discipline.id)
                      if (idx2 < 0) {
                        this.mainFields[idx].disciplines.push(discipline)
                      }
                    })
                  } else {
                    this.mainFields.push(field)
                  }
                })
                if (this.mainFields) {
                  for (let i = 0; i < this.mainFields.length; i++) {
                    for (let j = 0; j < this.mainFields[i].disciplines.length; j++) {
                      if (this.mainFields[i].disciplines[j].evaluated === null) {
                        this.mainFields[i].disciplines[j].evaluated = false
                      }
                    }
                  }
                }
                this.loadDisciplines()
              } else {
                this.hasMore = false
                this.disciplineEndSpinner = false
              }
            })
      }
      if (this.withAchievements) {
        ApiDiscipline.getDisciplinsList(this.page)
            .then(result => {
              if (result && result.data && result.data.fields && result.data.fields.length > 0) {
                this.page += 1
                result.data.fields.forEach(field => {
                  const idx = this.mainFields.findIndex(mf => mf.id === field.id)
                  if (idx >= 0) {
                    field.disciplines.forEach(discipline => {
                      const idx2 = this.mainFields[idx].disciplines.findIndex(d => d.id === discipline.id)
                      if (idx2 < 0) {
                        this.mainFields[idx].disciplines.push(discipline)
                      }
                    })
                  } else {
                    this.mainFields.push(field)
                  }
                })
                if (this.mainFields) {
                  for (let i = 0; i < this.mainFields.length; i++) {
                    for (let j = 0; j < this.mainFields[i].disciplines.length; j++) {
                      if (this.mainFields[i].disciplines[j].evaluated === null) {
                        this.mainFields[i].disciplines[j].evaluated = false
                      }
                    }
                  }
                }
                this.loadDisciplines()
              } else {
                this.hasMore = false
                this.disciplineEndSpinner = false
              }
            })
      }
      if (this.onlyEvaluated) {
        ApiDiscipline.getDisciplinsList(this.page, null, this.onlyEvaluated)
            .then(result => {
              if (result && result.data && result.data.fields && result.data.fields.length > 0) {
                this.page += 1
                result.data.fields.forEach(field => {
                  const idx = this.mainFields.findIndex(mf => mf.id === field.id)
                  if (idx >= 0) {
                    field.disciplines.forEach(discipline => {
                      const idx2 = this.mainFields[idx].disciplines.findIndex(d => d.id === discipline.id)
                      if (idx2 < 0) {
                        this.mainFields[idx].disciplines.push(discipline)
                      }
                    })
                  } else {
                    this.mainFields.push(field)
                  }
                })
                this.loadDisciplines()
              } else {
                this.hasMore = false
                this.disciplineEndSpinner = false
              }
            })
      }
    },
    getAllDisciplines () {
      this.showAll = true
      this.withAchievements = false
      this.onlyEvaluated = false
      this.page = 1
      this.hasMore = true
      this.mainFields = []
      this.loadDisciplines()
    },
    getWithAchievements () {
      this.showAll = false
      this.withAchievements = true
      this.onlyEvaluated = false
      this.page = 1
      this.hasMore = true
      this.mainFields = []
      this.loadDisciplines()
    },
    getEvaluatedDisciplines () {
      this.showAll = false
      this.withAchievements = false
      this.onlyEvaluated = true
      this.page = 1
      this.hasMore = true
      this.mainFields = []
      this.loadDisciplines()
    },
    multipleEvaluationModal () {
      this.$refs.evaluationModal.show()
    },
    multipleEvaluate () {
      if (this.chosenEvaluation) {
        this.$cable.subscribe({
          channel: 'ChatChannel',
          userId: this.currentUser
        })
        ApiDiscipline.multipleEvaluation(this.chosenEvaluation, this.showAll, this.onlyEvaluated)
            .then(result => {
              const jobId = result.data.jobId
              if (jobId !== undefined) {
                this.$refs.evaluationModal.hide()
                this.chosenEvaluation = null
                this.evaluationSpinner = true
                this.waitForJob(jobId, this.evaluationUpdate, this.evaluationError)
              }
            })
      } else {
        this.$toastr.e(this.$t('views.disciplines.chosen_evaluation_error'))
        setTimeout(() => {
          this.$refs.evaluationModal.show()
        }, 300)
      }
    },
    evaluationEsc () {
      this.$refs.evaluationModal.hide()
      this.chosenEvaluation = null
    },
    evaluationUpdate () {
      this.evaluationSpinner = false
      this.$router.go(0)
    },
    evaluationError () {
      this.evaluationSpinner = false
      this.unexpectedError()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';
  .component-container {
    padding-top: 20px;
  }

  .eval-info {
    color: #9d9d9d;
    font-size: .86rem;
  }

  .show-evaluated {
    color: $pcg-gray;
    font-size: $font-size-m;
    font-weight: 400;
    position: relative;
    bottom: .15rem;
  }

  .oneline {
    margin-bottom: 2rem;
  }

  .one-main-field {
    &:not(:last-child) {
      margin-bottom: 3.5rem;
    }
  }

  .header {
    padding: .2em!important;
    display: inline-block;
    width: 100%;
    clear: both;
    margin-bottom: 1.7rem;
  }

  .name {
    display: inline;
    float: left;
  }

  .count {
    display: inline;
    float: right;
    margin-right: .4rem;
    font-weight: 400;
    color: $pcg-dark-gray;
    font-size: $font-size-l;
  }

  .my-checkbox {
    margin-left: 1rem;
    position: relative;
    top: .4rem;
    float: left;
    display: inline;
    font-size: $font-size-m;
    /deep/ .pcg-checkbox-container {
      margin-right: 0 !important;
    }
  }

  .discipline-name {
    font-size: $font-size-l;
    font-weight: 600;
    color: $main-color;
    margin-bottom: .4rem;
    letter-spacing: .05rem;
    span {
      cursor: pointer;
    }
    display: inline;
    float: left;
    a {
      color: unset;
    }
  }
  .my-space {
    margin-bottom: .4rem;
  }
  .discipline-header {
    display: inline-block;
    width: 100%;
    clear: both;
  }
  .discipline-evaluated {
    display: inline;
    float: right;
  }
  .points-number {
    display: inline-block;
    background-color: $main-active-color;
    color: $pcg-white;
    padding: .2rem .6rem;
    border-radius: 8px;
    margin-right: .3rem;
    min-width: 53px;
    text-align: center;
    font-size: $font-size-m;
    font-weight: 400;
  }
  .points-text {
    font-size: 1em;
    font-weight: 400;
    color: $main-color;
    position: relative;
    bottom: .1rem;
  }
  .my-icon {
    width: 20px;
    height: 20px;
    margin-right: .2rem;
  }
  .filling-slots {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .2rem;
  }
  .filling-slots-percent {
    color: $main-color;
    font-weight: 600;
  }
  .my-text {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-dark-gray;
    margin-right: .4rem;
  }
  .my-number {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-s;
  }
  .my-icon-text {
    color: $pcg-gray;
    font-size: $font-size-s;
    font-weight: 400;
    position: relative;
    bottom: .1rem;
  }
  .my-icon-space {
    margin-bottom: .6rem;
  }
  .one-row {
    padding: .2em 0;
    &:not(:last-child) {
      border-bottom: 1.5px solid $pcg-lightest-gray-background;
      margin-bottom: 1rem;
    }
    &:not(:first-child) {
      margin-top: .4rem;
    }
    &.no-border {
      border: none;
    }
  }

  .icon-too-many {
    height: 17px;
    width: 17px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    position: relative;
    bottom: .2rem;
    margin-right: .4rem;
  }

  .icon-not-enough {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 30px;
    border: 2px solid;
    border-color: $pcg-red;
    margin-right: .2rem;
    position: relative;
    bottom: .15rem;
  }

  .no-manager {
    font-size: $font-size-s;
    font-weight: 400;
    color: $dn-orange;
    margin-right: .2rem;
  }

  .manager-info {
    display: inline-block;
  }

  .economic {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-bottom: .3rem;
  }

  /deep/ .switch {
    max-width: 32px !important;
    min-width: 32px !important;
  }

  /deep/ .box-container {
    padding-right: 0;
    padding-left: 0;
    border-radius: 15px;
  }

  .second-column {
    @media (max-width: 630.98px) {
      position: relative;
      left: .5rem;
    }
    @media (max-width: 575.98px) {
      left: 0;
    }
  }

  .my-spinner {
    color: $main-active-color;
    margin-right: .3rem;
    margin-left: .3rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-the-lightest-gray-background;
    color: $pcg-gray;
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }
  .button-outline:focus-visible {
    outline: 2px solid $main-color !important;
    outline-offset: 2px;
  }
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-spinner {
    color: $pcg-white;
  }
  .live-cable {
    color: $pcg-white;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .show-evaluated {
      color: $pcg-gray;
    }
    .count {
      color: $pcg-dark-gray;
    }
    .discipline-name {
      color: $main-color;
    }
    .points-number {
      background-color: $main-active-color;
      color: $pcg-white;
    }
    .points-text {
      color: $main-color;
    }
    .filling-slots {
      color: $pcg-gray;
    }
    .filling-slots-percent {
      color: $main-color;
    }
    .my-text {
      color: $pcg-dark-gray;
    }
    .my-number {
      color: $main-active-color;
    }
    .my-icon-text {
      color: $pcg-gray;
    }
    .one-row {
      &:not(:last-child) {
        border-bottom: 1.5px solid $pcg-lightest-gray;
      }
    }
    .icon-too-many {
      background-color: $main-color;
    }
    .icon-not-enough {
      background-color: $pcg-light-red;
    }
    .no-manager {
      color: $dn-orange;
    }
    .economic {
      color: $pcg-gray;
    }
    .my-spinner {
      color: $main-active-color;
    }
    .pcg-slide-tabs {
      background-color: $disabled-color;
    }
    .pcg-slide-tab-btn {
      background-color: $disabled-color !important;
    }
    .pcg-slide-tab-btn.active {
      background-color: $main-active-color !important;
    }
    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray;
      color: $pcg-gray;
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .my-spinner {
      color: $pcg-white;
    }
    .live-cable {
      color: $pcg-white;
    }
  }
</style>
