<template>
<div class="institutions-list">
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <label :for="$t('views.f_institution.name')" class="sr-only" style="position: relative; top: .2rem;">{{ $t('views.f_institution.name') }}</label>
      <pcg-text-input
          class="no-margin my-input"
          :id="$t('views.f_institution.name')"
          :show-label="false"
          :placeholder="$t('views.f_institution.name')"
          v-model="filters.name"
          @input="getFilteredInstitution"
      />
    </b-col>
  </b-row>
  <b-row style="margin-bottom: 2rem">
    <b-col>
      <span class="my-label">
        <label style="position: relative; top: .5rem;">{{ $t('views.f_institution.on_list') }}</label>
      </span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="tDictionary(yesNo)"
          :default-option="{ text: $t('general.select'), id: '' }"
          v-model="filters.onList"
          @input="getFilteredInstitution"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="10">
      <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="perPageOptions"
          :default-option="{ text: perPage.toString(), value: perPage }"
          v-model="perPage"
          @input="getFilteredInstitution"
      />
    </b-col>
    <b-col class="text-right">
      <pcg-btn v-if="hasRight('edit_own_dictionaries')"
               variant="additional" size="small" @click="institutionAdd">
        {{$t('general.add')}}
      </pcg-btn>
    </b-col>
  </b-row>
  <b-table v-if="financialInstitutions.length > 0"
           class="institution-table"
           borderless hover
           thead-class="sthead"
           tbody-tr-class="strow"
           :fields="fields"
           :items="financialInstitutions"
           :primary-key="financialInstitutions.id"
  >
    <template v-slot:cell(name)="data">
      {{ data.value }}
    </template>
    <template v-slot:cell(short)="data">
      {{ data.value }}
    </template>
    <template v-slot:cell(onList)="data">
      {{ tf(data.value) }}
    </template>
    <template v-slot:cell(inTerritory)="data">
      {{ tf(data.value) }}
    </template>
    <template v-slot:cell(id)="data">
      <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="institutionEdit(data.value)">
        <i class="fas fa-pen academy-icon" :id="`edit-financing-institution` + data.value"></i>
        <b-tooltip :target="`edit-financing-institution` + data.value">
          {{ $t('general.edit') }}
        </b-tooltip>
      </a>
    </template>
  </b-table>
  <nav :aria-label="$t('general.pagination')">
    <pcg-pagination
        v-model="pagination.page"
        :page-count="pagination.pages"
        :total-records="pagination.count"
        @input="getInstitutions"
        class="col-12 justify-content-center my-pagination"
        style="margin-top: 2rem"
        :only-event="true"
    />
  </nav>
  <b-modal ref="institutionModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="institutionSave"
           @cancel="institutionEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "institutionEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.f_institution.modal.title') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.f_institution.name')"
        v-model="mInstitution.name"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.f_institution.short')"
        v-model="mInstitution.short"
    />
    <pcg-checkbox
        class="my-field"
        :label="$t('views.entity_project.in_territory')"
        v-model="mInstitution.inTerritory"
    >{{ $t('views.entity_project.in_territory') }}</pcg-checkbox>
  </b-modal>
</div>
</template>

<script>
import ApiInstitution from '../../api/financing_institution'
import hasRight from '../../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import { mapGetters } from 'vuex'
import tf from '../../mixins/tf'
import translateDictionary from '../../mixins/translate_dictionary'

export default {
  name: 'FinancingInstitutionList',
  mixins: [hasRight, setTitle, clickaway, tf, translateDictionary],
  data: function () {
    return {
      headerTitle: 'views.f_institution.title',
      headerSubtitle: 'views.f_institution.subtitle',

      filters: {
        name: null,
        onList: null
      },
      financialInstitutions: [],
      locals: [],
      mInstitution: {
        id: null,
        name: null,
        short: null,
        inTerritory: null
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 25
    }
  },
  computed: {
    ...mapGetters('auth', ['academyId']),
    fields: function () {
      return [
        {
          key: 'name',
          label: this.$t('views.f_institution.name'),
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'short',
          label: this.$t('views.f_institution.short'),
          thStyle: 'width: 10rem;'
        },
        {
          key: 'onList',
          label: this.$t('views.f_institution.on_list'),
          thStyle: 'width: 10rem;'
        },
        {
          key: 'inTerritory',
          label: this.$t('views.entity_project.in_territory'),
          thStyle: 'width: 20rem;'
        },
        {
          key: 'id',
          label: '',
          thStyle: 'width: 5rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    },
    yesNo () {
      return [
        { text: 'Wybierz', text_en: 'Select', id: '' },
        { text: 'Tak', text_en: 'Yes', id: true },
        { text: 'Nie', text_en: 'No', id: false }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiInstitution.getInstitutions(this.$route.query)
      .then(result => {
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'name')) {
          this.filters.name = query.name
        }
        if (Object.prototype.hasOwnProperty.call(query, 'per_page')) {
          this.perPage = query.per_page
        } else {
          this.perPage = 25
        }
        this.financialInstitutions = result.data.datas
        this.pagination = result.data.pagination
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    institutionAdd () {
      this.mInstitution = {
        id: null,
        name: null,
        short: null,
        inTerritory: null
      }
      this.$refs.institutionModal.show()
    },
    institutionEdit (id) {
      const institution = this.financialInstitutions.find(el => {
        return el.id === id
      })
      Object.assign(this.mInstitution, institution)
      this.$refs.institutionModal.show()
    },
    institutionEsc () {
      this.mInstitution = {
        id: null,
        name: null,
        short: null,
        inTerritory: null
      }
    },
    institutionSave (modalEvt) {
      modalEvt.preventDefault()
      if (this.mInstitution.id) {
        const p = {}
        Object.assign(p, this.mInstitution)
        const idx = this.financialInstitutions.findIndex(el => {
          return this.mInstitution.id === el.id
        })
        ApiInstitution.updateInstitution(this.mInstitution)
          .then(result => {
            if (idx > -1) {
              Object.assign(this.financialInstitutions[idx], p)
            }
            this.$toastr.s(this.$t('general.success'))
            this.$refs.institutionModal.hide()
            this.mInstitution = {
              id: null,
              name: null,
              short: null
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mInstitution, p)
          })
      } else {
        ApiInstitution.createInstitution(this.mInstitution)
          .then(result => {
            if (result) {
              this.$router.go(0)
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
          })
      }
    },
    getInstitutions () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (key === 'onList') {
          // eslint-disable-next-line eqeqeq
          if (this.filters[key] != null && this.filters[key].toString() !== '') {
            oFilters[key] = this.filters[key]
          }
        } else {
          if (this.filters[key] != null && this.filters[key].toString() !== '' && this.filters[key]) {
            oFilters[key] = this.filters[key]
          }
        }
      })
      let query = {}
      query = Object.assign(query, oFilters)
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'financing_institutions_list_path', query: query })

      ApiInstitution.getInstitutions(query)
        .then(result => {
          const query = this.$route.query
          if (Object.prototype.hasOwnProperty.call(query, 'name')) {
            this.filters.name = query.name
          }
          this.financialInstitutions = result.data.datas
          this.pagination = result.data.pagination
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFilteredInstitution () {
      this.pagination.page = 1
      this.getInstitutions()
    }

  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .institution-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.institution-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .institution-edit {
    color: $main-active-color;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    ::v-deep .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    ::v-deep .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .button-element {
    cursor: pointer;
  }

  .my-pen {
    color: $main-active-color;
  }

  .academy-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .my-label {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 400;
    display: inline;
    float: left;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .institution-edit {
      color: $main-active-color;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-pen {
      color: $main-active-color;
    }
    .academy-icon {
      background-color: #ecf9fd;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .my-select-short {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .my-label {
      color: $pcg-gray;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .institution-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          color: $pcg-dark-gray !important;
          &.td-left {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
