import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store/index'
import qs from 'qs'

import DevSessionsIndex from '../views/dev_sessions/DevSessionsIndex'
import Dictionaries from '../views/Dictionaries'
import DictionariesIndex from '../views/dictionaries/DictionariesIndex'
import DictionariesEdit from '../views/dictionaries/DictionariesEdit'
import DictionaryWords from '../views/DictionaryWords'
import DictionaryWordsIndex from '../views/dictionary_words/DictionaryWordsIndex'
import DictionaryWordsRecent from '../views/dictionary_words/DictionaryWordsRecent'
import DictionaryWordsEdit from '../views/dictionary_words/DictionaryWordsEdit'
import DictionaryWordsNew from '../views/dictionary_words/DictionaryWordsNew'
import DictionaryWordsTabs from '../views/dictionary_words/DictionaryWordsTabs'
import DictionaryAcceptances from '../views/DictionaryAcceptances'
import DictionaryAcceptancesIndex from '../views/dictionary_acceptances/DictionaryAcceptancesIndex'
import DictionaryAcceptancesEdit from '../views/dictionary_acceptances/DictionaryAcceptancesEdit'
import DictionaryAcceptanceWords from '../views/DictionaryAcceptanceWords'
import DictionaryAcceptanceWordsIndex from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsIndex'
import DictionaryAcceptanceWordsEdit from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsEdit'
import DictionaryAcceptanceWordsNew from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsNew'
import DictionaryDisciplines from '../views/disciplines/DictionaryDisciplines'
import ArtAchievements from '../views/arts/ArtAchievements'
import ArtXlsList from '../views/arts/ArtXlsList'
import Login from '../views/Login'
import AcademiesList from '../views/academies/AcademiesList'
import AchievementEmpty from '../components/AchievementEmpty'
import AchievementNew from '../views/achievements/AchievementNew'
import AchievementShow from '../views/achievements/AchievementShow'
import AchievementList from '../views/achievements/AchievementList'
import Evaluations from '../views/Evaluations'
import FacultiesList from '../views/academies/FacultiesList'
import ImportPCG from '../views/achievements/ImportPCG'
import ConferencesList from '../views/publishers/ConferencesList'
import DisciplineShow from '../views/disciplines/DisciplineShow'
import Disciplines from '../views/disciplines/Disciplines'

import ScientistsList from '../views/scientists/ScientistsList'
import Scientist from '../views/scientists/Scientist'
import StatementsList from '../views/statements/StatementsList'
import DisciplineStatements from '../views/statements/DisciplineStatements'
import Employee from '../views/Employee'
import EntityProjectList from '../views/dictionaries/EntityProjectList'
import FinancingInstitutionList from '../views/dictionaries/FinancingInstitutionList'
import FinancingSourceList from '../views/dictionaries/FinancingSourceList'
import ScientistDashboard from '../views/ScientistDashboard'
import EmptyView from '../views/EmptyView'
import RedirectView from '../views/RedirectView'
// import OrcidIntegration from '../views/OrcidIntegration'
import OrcidPublications from '../views/orcid/OrcidPublications'
import ScientistPublications from '../views/ScientistPublications'
import PolonList from '../views/polon/PolonList'
import PbnToken from '../views/polon/PbnToken'
import EmployeeXlsList from '../views/employee_import/EmployeeXlsList'
import ImportPublication from '../views/polon/ImportPublication'
import ImportPbnApi from '../views/polon/ImportPbnApi'
import PeriodicalsList from '../views/publishers/PeriodicalsList'
import PublicationStatementList from '../views/publication_statement/PublicationStatementList'
import PublishersList from '../views/publishers/PublishersList'
import ExportXmls from '../views/polon/ExportXmls'
import IconRemove from '../components/icons/IconRemove'
import NumberNStatements from '../views/statements/NumberNStatements'
import Rights from '../views/academies/Rights'
import UsersList from '../views/users/UsersList'
import { user } from '../store/modules/user'
import InstitutionsList from '../views/academies/InstitutionsList'
import ExportReport from '../views/polon/ExportReport'
import ExportOrcidReport from '../views/orcid/ExportReport'
import ImportPolon from '../views/polon/ImportPolon'
import AccessibilityDeclaration from '../views/AccessibilityDeclaration'
import ImportPolonEmployees from '../views/polon/ImportPolonEmployees'
import Histories from '../views/Histories'
import ImportPbnDictionaries from '../views/polon/ImportPbnDictionaries'
import DuplicateReport from '../views/DuplicateReport.vue'

Vue.use(VueRouter)
Vue.component('icon-remove', IconRemove)

function hasRight (right) {
  const rights = store.getters['auth/rights']
  return (rights && rights[right]) || false
}

function subDomain () {
  const s = document.querySelector('#app-loader').dataset.subDomain
  const sd = s.length > 0 ? s : null
  return sd
}

function showAccessibleDeclaration () {
  return process.env.ACCESSIBILITY_DECLARATION == 1
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'exact-active',
  routes: [
    {
      path: '/',
      name: 'root_path',
      component: Login
    },
    {
      path: '/login',
      name: 'login_path',
      component: Login
    },
    {
      path: '/openid-connect',
      name: 'openid_login_path',
      component: Login
    },
    {
      path: '/accessibility_declaration',
      name: 'accessibility_declaration_path',
      component: AccessibilityDeclaration,
      meta: (router) => ({ auth: false, roles: [], allowed: showAccessibleDeclaration() })
    },
    {
      // TODO: user dashboard
      path: '/',
      name: 'user_dashboard_path',
      component: Login
    },
    {
      path: '/no_where',
      name: 'no_where_path',
      meta: { block: true }
    },
    {
      path: '/academies',
      name: 'academies_list_path',
      components: {
        default: AcademiesList,
        global: InstitutionsList
      },
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_academies') || hasRight('list_own_academy')) })
    },
    {
      path: '/art_achievements',
      name: 'art_achievements_path',
      component: ArtAchievements,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries') || subDomain() !== 'global') })
    },
    {
      path: '/art_import',
      name: 'art_import_path',
      component: ArtXlsList,
      meta: (router) => ({ auth: true, roles: ['analityknaukowy'], allowed: (subDomain() !== 'global') })
    },
    {
      path: '/achievement/empty',
      name: 'achievement_empty_path',
      component: AchievementEmpty,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('add_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/achievement/new',
      name: 'achievement_new_path',
      component: AchievementNew,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('add_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/achievement/:id/edit',
      name: 'achievement_edit_path',
      params: { id: 1 },
      component: AchievementNew,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_achievement') || hasRight('edit_own_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/achievement/:id/show',
      name: 'achievement_show_path',
      params: { id: 1 },
      component: AchievementShow,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('view_achievement') || hasRight('view_own_achievement')) })
    },
    {
      path: '/achievements',
      name: 'achievement_list_path',
      component: AchievementList,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/achievements/import',
      name: 'import_pcg_path',
      component: ImportPCG,
      meta: (router) => ({ auth: true, roles: ['analityknaukowy'], allowed: (subDomain() !== 'global') })
    },
    {
      path: '/conferences-list',
      component: ConferencesList,
      name: 'conferences_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
    },
    {
      path: '/dev_login',
      name: 'dev_sessions_path',
      component: DevSessionsIndex,
      meta: { env: 'development', localLogin: '1', loginView: '1' }
    },
    {
      path: '/dictionaries',
      component: Dictionaries,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) }),
      children: [
        {
          path: '',
          name: 'dictionaries_path',
          component: DictionariesIndex,
          meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
        },
        {
          path: ':id/edit',
          name: 'dictionaries_edit_path',
          component: DictionariesEdit,
          meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
        },
        {
          path: ':dictionaryId/dictionary_words',
          component: DictionaryWords,
          meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) }),
          children: [
            {
              path: '',
              component: DictionaryWordsTabs,
              meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) }),
              children: [
                {
                  path: '',
                  name: 'dictionary_words_path',
                  component: DictionaryWordsTabs,
                  meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
                },
                {
                  path: 'recent',
                  name: 'dictionary_words_recent_path',
                  component: DictionaryWordsTabs,
                  meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
                },
                {
                  path: 'accepted',
                  name: 'dictionary_words_accepted_path',
                  component: DictionaryWordsTabs,
                  meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) }),
                  props: { status: 'accepted' }
                },
                {
                  path: 'rejected',
                  name: 'dictionary_words_rejected_path',
                  component: DictionaryWordsTabs,
                  meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) }),
                  props: { status: 'rejected' }
                }
              ]
            },
            {
              path: 'new',
              name: 'dictionary_words_new_path',
              component: DictionaryWordsNew,
              meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
            },
            {
              path: ':id/edit',
              name: 'dictionary_words_edit_path',
              component: DictionaryWordsEdit,
              meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
            }
          ]
        }
      ]
    },
    {
      path: '/dictionary_acceptances',
      component: DictionaryAcceptances,
      meta: { auth: true },
      children: [
        {
          path: '',
          name: 'dictionary_acceptances_path',
          component: DictionaryAcceptancesIndex,
          meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] }
        },
        {
          path: ':id/edit',
          name: 'dictionary_acceptances_edit_path',
          component: DictionaryAcceptancesEdit,
          meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] }
        },
        {
          path: ':dictionaryId/dictionary_acceptance_words',
          component: DictionaryAcceptanceWords,
          meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] },
          children: [
            {
              path: '',
              name: 'dictionary_acceptance_words_path',
              component: DictionaryAcceptanceWordsIndex,
              meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] }
            },
            {
              path: 'new',
              name: 'dictionary_acceptance_words_new_path',
              component: DictionaryAcceptanceWordsNew,
              meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] }
            },
            {
              path: ':id/edit',
              name: 'dictionary_acceptance_words_edit_path',
              component: DictionaryAcceptanceWordsEdit,
              meta: { auth: true, roles: ['pracownik', 'analityknaukowy'] }
            }
          ]
        }
      ]
    },
    {
      path: '/dictionary_disciplines',
      name: 'dictionary_disciplines_path',
      component: DictionaryDisciplines,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries' || subDomain() !== 'global')) })
    },
    {
      path: '/disciplines',
      name: 'disciplines_path',
      component: Disciplines,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_discipline') || hasRight('list_own_discipline') || subDomain() !== 'global') })
    },
    {
      path: '/discipline/:id',
      name: 'discipline_show_path',
      param: { id: 1 },
      component: DisciplineShow,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_discipline') || hasRight('list_own_discipline') || subDomain() !== 'global') })
    },
    {
      path: '/employee_import',
      name: 'employee_import_path',
      component: EmployeeXlsList,
      meta: (router) => ({ auth: true, roles: ['analityknaukowy'], allowed: (subDomain() !== 'global') })
    },
    {
      path: '/employee/:id',
      name: 'employee_path',
      params: { id: 1 },
      component: Employee,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('view_employee') || subDomain() !== 'global') })
    },
    {
      path: '/entity_projects_list',
      component: EntityProjectList,
      name: 'entity_projects_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries') || subDomain() !== 'global') })
    },
    {
      path: '/export_xmls',
      name: 'export_xml_path',
      component: ExportXmls,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn') || subDomain() !== 'global') })
    },
    {
      path: '/faculties',
      name: 'faculties_list_path',
      component: FacultiesList,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_unit') && subDomain() !== 'global') })
    },
    {
      path: '/pbn_token',
      name: 'pbn_token_path',
      component: PbnToken,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn')) })
    },
    {
      path: '/callback',
      name: 'polon_callback_path',
      component: PbnToken,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn')) })
    },
    {
      path: '/pbn/callback',
      name: 'polon_pbn_callback_path',
      component: PbnToken,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn')) })
    },
    // {
    //   path: '/export_polon',
    //   name: 'export_polon_path',
    //   component: ExportXmls,
    //   meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn')) })
    // },
    {
      path: '/evaluations',
      name: 'evaluations_list_path',
      component: Evaluations,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_evaluation') || hasRight('edit_evaluation')) })
    },
    // {
    //   path: '/orcid_integration/:id',
    //   name: 'orcid_integration_path',
    //   params: { id: 1 },
    //   component: OrcidIntegration,
    //   meta: { auth: true, roles: ['pracownik', 'naukowiec', 'analityknaukowy', 'kierownikdyscypliny'] }
    // },
    {
      path: '/financing_institutions_list',
      component: FinancingInstitutionList,
      name: 'financing_institutions_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries') || subDomain() !== 'global') })
    },
    {
      path: '/financing_sources_list',
      component: FinancingSourceList,
      name: 'financing_sources_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries') || subDomain() !== 'global') })
    },
    {
      path: '/histories',
      name: 'histories_path',
      component: Histories,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('view_history_report') || subDomain() !== 'global') })
    },
    {
      path: '/no_rights',
      name: 'no_rights_path',
      component: EmptyView,
      meta: { env: 'development', localLogin: '1', loginView: '1' }
    },
    {
      path: '/redirect',
      name: 'redirect_path',
      component: RedirectView,
      meta: { env: 'development', localLogin: '1', loginView: '1' }
    },
    {
      path: '/orcid_publications/:id',
      name: 'orcid_publications_path',
      params: { id: 1 },
      component: OrcidPublications,
      meta: (router) => ({ auth: true, roles: [], allowed: ((hasRight('import_orcid') || hasRight('import_own_orcid')) && subDomain() !== 'global') })
    },
    {
      path: '/pbn',
      name: 'pbn_path',
      component: PolonList,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('import_pbn') || subDomain() !== 'global') })
    },
    {
      path: '/import_pbn',
      name: 'import_pbn_path',
      component: ImportPublication,
      meta: (router) => ({ auth: true, roles: ['analityknaukowy'], allowed: (subDomain() !== 'global') })
    },
    {
      path: '/import_pbn_api',
      name: 'import_pbn_api_path',
      component: ImportPbnApi,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('import_pbn') || subDomain() !== 'global') })
    },
    {
      path: '/import_polon',
      name: 'import_polon_path',
      component: ImportPolon,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('import_polon_achievements') || subDomain() !== 'global') })
    },
    {
      path: '/import_polon_employees',
      name: 'import_polon_employees_path',
      component: ImportPolonEmployees,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('import_polon_employees') || subDomain() !== 'global') })
    },
    {
      path: '/periodicals-list',
      component: PeriodicalsList,
      name: 'periodicals_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
    },
    {
      path: '/publication_statement_import',
      name: 'publication_statement_path',
      component: PublicationStatementList,
      meta: (router) => ({ auth: true, roles: ['analityknaukowy'], allowed: (subDomain() !== 'global') })
    },
    {
      path: '/pbn_dictionaries_import',
      name: 'pbn_dictionaries_import_path',
      component: ImportPbnDictionaries,
      meta: (router) => ({ auth: true, roles: ['manager'], allowed: (subDomain() === 'global' || subDomain() === 'public') })
    },
    {
      path: '/publishers-list',
      component: PublishersList,
      name: 'publishers_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')) })
    },
    {
      path: '/reports',
      component: ExportReport,
      name: 'reports_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_pbn') || subDomain() !== 'global') })
    },
    {
      path: '/orcid_reports',
      component: ExportOrcidReport,
      name: 'orcid_reports_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('export_orcid') || subDomain() !== 'global') })
    },
    {
      path: '/duplicate_reports',
      component: DuplicateReport,
      name: 'duplicate_reports_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('duplicate_report') || subDomain() !== 'global') })
    },
    {
      path: '/rights',
      component: Rights,
      name: 'rights_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_roles') || hasRight('edit_own_roles') || subDomain() !== 'global') })
    },
    {
      path: '/scientist_dashboard',
      name: 'scientist_dashboard_path',
      component: ScientistDashboard,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_own_employee') || subDomain() !== 'global') })
    },
    {
      path: '/scientist/:id',
      name: 'scientist_path',
      params: { id: 1 },
      component: Scientist,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_employee') || subDomain() !== 'global') })
    },
    {
      path: '/scientists-list',
      component: ScientistsList,
      name: 'scientists_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_employees') || subDomain() !== 'global') })
    },
    {
      path: '/scientist_publications/',
      name: 'scientist_publications_path',
      component: ScientistPublications,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_own_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/scientist_publications/:id',
      name: 'other_scientist_publications_path',
      params: { id: 1 },
      component: ScientistPublications,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_achievement') || hasRight('list_employee_achievement') || subDomain() !== 'global') })
    },
    {
      path: '/statements',
      component: StatementsList,
      name: 'statements_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_statements') || hasRight('edit_own_statements') || subDomain() !== 'global') })
    },
    {
      path: '/statements/discipline/:id',
      name: 'statements_discipline_path',
      param: { id: 1 },
      component: DisciplineStatements,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_statements') || hasRight('edit_own_statements') || subDomain() !== 'global') })
    },
    {
      path: '/statements/number_n/:id',
      name: 'statements_number_n_path',
      param: { id: 1 },
      component: NumberNStatements,
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('edit_statements') || hasRight('edit_own_statements') || subDomain() !== 'global') })
    },
    {
      path: '/users',
      component: UsersList,
      name: 'users_list_path',
      meta: (router) => ({ auth: true, roles: [], allowed: (hasRight('list_users') || hasRight('list_own_users')) })
    }
  ],
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    const result = qs.stringify(query, { arrayFormat: 'brackets' })
    return result ? ('?' + result) : ''
  }
})

router.beforeEach((to, from, next) => {
  const meta = (typeof to.meta === 'function') ? to.meta() : to.meta

  if (!store.getters['auth/signedIn'] && !['dev_sessions_path', 'login_path', 'openid_login_path', 'root_path', 'no_rights_path', 'redirect_path', 'accessibility_declaration_path'].includes(to.name)) {
    Vue.$cookies.set('redirect_url', to.fullPath)
    return next({ name: 'login_path' })
  }
  if (store.getters['auth/signedIn'] && store.getters['auth/expire'] && !['dev_sessions_path', 'login_path', 'openid_login_path', 'root_path', 'no_rights_path', 'redirect_path', 'accessibility_declaration_path'].includes(to.name)) {
    // token stracił ważność
    store.dispatch('auth/signOut').then((result) => {
      return next({ name: 'login_path' })
    })
    // return next({ name: 'login_path' })
  }

  store.dispatch('auth/updateState')
  store.dispatch('page/setContrast')
  if (meta.allowed === false || meta.block) {
    return next(false)
  }
  if (to.name !== from.name) {
    store.dispatch('header/clearHeader')
    store.dispatch('pageCover/clearBackgroundImage')
    store.dispatch('pageCover/clearBackgroundImageStyle')
    store.dispatch('pageCover/clearBackgroundImageClasses')
    store.dispatch('sidebarMenu/clearSidebarMenuClasses')
  }

  if (to && (meta.env || meta.localLogin || meta.loginView)) {
    // eslint-disable-next-line eqeqeq
    if (process.env.RAILS_ENV === meta.env || process.env.LOCAL_LOGIN == meta.localLogin || process.env.LOGIN_VIEW == meta.loginView) {
      return next()
    } else if (to.name === 'no_rights_path' || to.name === 'redirect_path' || to.name === 'accessibility_declaration_path') {
      return next()
    } else {
      return next({ name: 'login_path' })
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/signedIn']) {
      if (to.meta.roles) {
        const userRole = store.getters['auth/currentRole']
        if (to.meta.roles.includes(userRole) || meta.allowed) {
          next()
        } else {
          if (Vue.$cookies.get('redirect_url')) {
            let url = window.location.origin
            url += Vue.$cookies.get('redirect_url')
            Vue.$cookies.remove('redirect_url')
            setTimeout(() => {
              window.location = url
            }, 2000)
          } else {
            if (['pracownik', 'kierownikdyscypliny', 'analityknaukowy'].includes(userRole)) {
              next({ name: 'disciplines_path' })
            } else if (userRole === 'naukowiec') {
              if (hasRight('viewOwnEmployee')) {
                next({ name: 'scientist_dashboard_path' })
              } else {
                next({ name: 'scientist_publications_path' })
              }
            } else if (userRole === 'manager') {
              next({ name: 'academies_list_path' })
            } else {
              next({ name: 'achievement_list_path' })
            }
          }
        }
      } else {
        next()
      }
    } else {
      const redirectTo = {
        name: name, params: to.params, query: to.query
      }
      store.dispatch('auth/setRedirectTo', redirectTo)
      next({ name: 'login_path' })
    }
  } else {
    const userRole = store.getters['auth/currentRole']
    if (userRole) {
      if (Vue.$cookies.get('redirect_url')) {
        let url = window.location.origin
        url += Vue.$cookies.get('redirect_url')
        Vue.$cookies.remove('redirect_url')
        setTimeout(() => {
          window.location = url
        }, 2000)
      }

      if (store.getters['auth/signedIn'] && ['login_path', 'root_path'].includes(to.name)) {
        if (userRole === 'naukowiec' && from.name !== 'scientist_dashboard_path') {
          if (hasRight('viewOwnEmployee')) {
            next({ name: 'scientist_dashboard_path' })
          } else {
            next({ name: 'scientist_publications_path' })
          }
        } else if (userRole === 'manager' && from.name !== 'academies_list_path') {
          next({ name: 'academies_list_path' })
        } else if (['pracownik', 'kierownikdyscypliny', 'analityknaukowy'].includes(userRole) && from.name !== 'disciplines_path') {
          next({ name: 'disciplines_path' })
        } else if (hasRight('list_discipline')) {
          next({ name: 'disciplines_path' })
        } else if (hasRight('list_achievement')) {
          next({ name: 'achievement_list_path' })
        } else if (hasRight('list_users') || hasRight('list_own_users')) {
          next({ name: 'users_list_path' })
        } else if (!hasRight('list_discipline') && !hasRight('list_achievement') && !hasRight('list_users') && !hasRight('list_own_users')) {
          next({ name: 'redirect_path' })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      // dev_seesions_path, accessibility_declaration_path, no_rights_path i wszystkie kolejne strony na które można dostać się
      // nie będąc zalogowanym trzeba dodać do wyjątków w app.vue w checkForNetworkError
      if (['login_path', 'dev_sessions_path', 'root_path', 'accessibility_declaration_path'].includes(to.name)) {
        // if (store.getters['auth/signedIn']) {
        //   store.dispatch('auth/signOut').then((result) => {
        //     return next({name: 'no_rights_path'})
        //   })
        // } else {
        next()
        // }
      } else {
        next({ name: 'no_rights_path' })
      }
    }
  }
})
export default router
